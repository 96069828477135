import Navbar from "./Navbar";
import NFTTile from "./NFTTile";
import MarketplaceJSON from "../Marketplace.json";
import { useState } from "react";
import { Network, Alchemy } from "alchemy-sdk";
    const settings = {
        apiKey: "JAFC1ANRtXdH-_qdMGKp0R53m7A8_9ds", // Alchemy API Key.
        network: Network.ETH_GOERLI, // Network.
    };


export default function Marketplace() {
    const [data, updateData] = useState([]);
    const [dataFetched, updateFetched] = useState(false);
    const [address, updateAddress] = useState("0x");
    const [totalPrice, updateTotalPrice] = useState("0");

//const [data, updateData] = useState();
//const [dataFetched, updateFetched] = useState(false);


async function getAllNFTsAlchemy() {

    //alert ("Welcome to Bitrade");
    const ethers = require("ethers");
      
    //var listOfNFTs = new Array();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    //alert("Signer Obtenido: " + signer.getAddress);
    //alert("provider: " addr);
    //Pull the deployed contract instance
    let contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer);
    //alert(contract.address);
    const alchemyObject = new Alchemy(settings);
  
    // Print owner's wallet address:
    const addr = await signer.getAddress();
    updateAddress(addr);
    //alert(addr);
      
    // Print total NFT count returned in the response:
    //const nftsForOwner = await alchemyObject.nft.getNftsForOwner(addr);

    // Flag to omit metadata
    const omitMetadata = false;

    // Get all NFTs
    const allNFTs = await alchemyObject.nft.getNftsForContract(contract.address, {
       omitMetadata: omitMetadata,
     });


    //alert("ALL " +allNFTs);
    //const totalNFTsFound = nftsForOwner.totalCount;
    //alert (totalNFTsFound);
    var items = new Array(); 
    
    for (const nft of allNFTs.nfts) {
        let contract = nft.contract.address;
        //alert(contract);
        //alert(nft.tokenId);
      
        //alert("entre token pre Id: " + nft.tokenId);
        const response = await alchemyObject.nft.getNftMetadata(contract, nft.tokenId);
        const price = response.rawMetadata.price;
        let item = {
            price,
            tokenId: response.tokenId,
            seller: response.contract.contractDeployer,
            contract: response.contract.address,
            image: response.rawMetadata.image,
            name: response.title,
            description: response.description,
        }
      
        items.push(item);
      
    }

    //alert (typeof (items));
    updateData(items);
    updateFetched(true);
    updateAddress(addr);

}












async function getAllNFTs() {
    const ethers = require("ethers");
    alert("inicio get all nft")
    //After adding your Hardhat network to your metamask, this code will get providers and signers
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    //alert("Signer Obtenido: " + signer.getAddress);
    //alert("provider: " addr);
    //Pull the deployed contract instance
    let contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer);
    //create an NFT Token
    let transaction = await contract.getAllNFTs();
    //alert("Doble");
    //alert ("trans " +transaction);
    //Fetch all the details of every NFT from the contract and display
    //alert("llegue aqui");
    const items = await Promise.all(transaction.map(async i => {
        const tokenURI = await contract.tokenURI(i.tokenId);
        //alert ("tokenuri: " + tokenURI + "FIN");
        alert ("axios");
        //let meta = await axios.get(tokenURI);
        alert ("defini meta: ");
        //meta = meta.data;

        alert("estoy obteniendo tokens")
        let price = ethers.utils.formatUnits(i.price.toString(), 'ether');
        let item = {
            price,
            tokenId: i.tokenId.toNumber(),
            seller: i.seller,
            owner: i.owner,
        //    image: meta.image,
        //    name: meta.name,
        //    description: meta.description,
        }
        alert (price);
        //alert (i.seller);
        //alert(meta.description);
        data.unshift(item);
        return item;
    }))
   alert("terminé de obtener tokens")
    dataFetched=true;
    //updateFetched(true);
    updateData(items);
    //sampleData=items;                                                                                                               
    alert(items[0]);
}

if(!dataFetched)
    getAllNFTsAlchemy();

return (
    <div>
        <Navbar></Navbar>
        <div className="flex flex-col place-items-center mt-20">
             <div className="flex mt-5 justify-between flex-wrap max-w-screen-xl text-center">
                {data.map((value, index) => {
                    return <NFTTile data={value} key={index}></NFTTile>;
                })}
           </div>
           <div></div>
        </div>            
    </div>
);

}