//require('dotenv').config();
//const key = process.env.REACT_APP_PINATA_KEY;
//const secret = process.env.REACT_APP_PINATA_SECRET;

const axios = require('axios');
const FormData = require('form-data');

export const uploadJSONToIPFS = async(JSONBody) => {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    //making axios POST request to Pinata ⬇️
    return axios 
        .post(url, JSONBody, {
            headers: {
//                pinata_api_key: key,
 //               pinata_secret_api_key: secret,
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIxMGY0NGE0My1kYjMwLTRlN2UtYWRjNi0yNGNkY2I3YTY3MDMiLCJlbWFpbCI6InhhdG9tYUB5b3BpLmxpbmsiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX0seyJpZCI6Ik5ZQzEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiMTY4Njg5ZjdkNGQ2N2MzYjUxYzkiLCJzY29wZWRLZXlTZWNyZXQiOiI4ZjgzNzJhM2IwNTJhZDc5MmUyYWJhYzA3NzQxZTVhNzkzMzZjODNhM2E4M2E0NzExMWRlOTUyNjNlM2FmYWYwIiwiaWF0IjoxNjY3NjEwODE3fQ.Vb5BsTiQPtbiQXidaWvQwx5_6Bs4v4OZcBN9Kd_pOl8' 

            }
        })
        .then(function (response) {
           return {
               success: true,
               pinataURL: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash
           };
        })
        .catch(function (error) {
            console.log(error)
            return {
                success: false,
                message: error.message,
            }

    });
};

export const uploadFileToIPFS = async(file, filename) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    //making axios POST request to Pinata ⬇️
    //alert("llegue a piñata")
    //alert(key);
    //alert(secret);
    let data = new FormData();
    //alert(file.name)
    data.append('file', file);
    //alert("archivo en forma")
    //alert(file.path)
    const metadata = JSON.stringify({
        name: filename,
        keyvalues: {
            exampleKey: 'exampleValue'
        }
    });
    data.append('pinataMetadata', metadata);
    alert("metaddata de archivo en piñata agregada")
    //pinataOptions are optional
    const pinataOptions = JSON.stringify({
        cidVersion: 1
    });
    data.append('pinataOptions', pinataOptions);
    alert("opciones de arhivo pin piñata agregadas");
    alert(url);
    return axios 
        .post(url, data, {
            maxBodyLength: 'Infinity',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIxMGY0NGE0My1kYjMwLTRlN2UtYWRjNi0yNGNkY2I3YTY3MDMiLCJlbWFpbCI6InhhdG9tYUB5b3BpLmxpbmsiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX0seyJpZCI6Ik5ZQzEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiMTY4Njg5ZjdkNGQ2N2MzYjUxYzkiLCJzY29wZWRLZXlTZWNyZXQiOiI4ZjgzNzJhM2IwNTJhZDc5MmUyYWJhYzA3NzQxZTVhNzkzMzZjODNhM2E4M2E0NzExMWRlOTUyNjNlM2FmYWYwIiwiaWF0IjoxNjY3NjEwODE3fQ.Vb5BsTiQPtbiQXidaWvQwx5_6Bs4v4OZcBN9Kd_pOl8'            }
        })
        .then(function (response) {
            console.log("image uploaded", response.data.IpfsHash)
            alert(response.data.IpfsHash)
            alert("salgo de pinata")
            return {
               success: true,
               pinataURL: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash
           };
        })
        .catch(function (error) {
            console.log(error)
            alert("terminó?")
            alert(error)
            return {
                success: false,
                message: error.message,
            }

    });
};