import Navbar from "./Navbar";
import axie from "../tile.jpeg";
import { useLocation, useParams } from 'react-router-dom';
import MarketplaceJSON from "../Marketplace.json";
import { useState } from "react";
import { Network, Alchemy } from "alchemy-sdk";


const settings = {
        apiKey: "JAFC1ANRtXdH-_qdMGKp0R53m7A8_9ds", // Alchemy API Key.
        network: Network.ETH_GOERLI, // Network.
    };


export default function NFTPage (props) {

    const [data, updateData] = useState({});
    const [dataFetched, updateDataFetched] = useState(false);
    const [message, updateMessage] = useState("");
    const [currAddress, updateCurrAddress] = useState("0x");

    const [address, updateAddress] = useState("0x");
    

    
async function getNFTData(tokenId) {
    const ethers = require("ethers");
    const alchemyObject = new Alchemy(settings);
    const alchemyOwner = new Alchemy(settings);      
    // Print owner's wallet address:
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const addr = await signer.getAddress();
    updateAddress(addr);
    //alert(addr);
    //Pull the deployed contract instance
    let contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer)
    //alert("contrato :" + contract.address);
    //alert(tokenId);
    // Print total NFT count returned in the response:
    //const nftsForOwner = await alchemyObject.nft.getNftsForOwner(addr);
        //alert(nftsForOwner);
        //const totalNFTsFound = nftsForOwner.totalCount;
        //alert (totalNFTsFound);
        
      //  var items = new Array(); 
        const response = await alchemyObject.nft.getNftMetadata(contract.address, tokenId);
        const owner = await alchemyOwner.nft.getOwnersForNft(contract.address, tokenId);
        const index = owner.owners.length;
        //alert (index);
        //alert(owner.owners[index-1]);
        const price = response.rawMetadata.price;
        let item = {
                price,
                tokenId: response.tokenId,
                seller: response.contract.contractDeployer,
                contract: response.contract.address,
                owner: owner.owners[index-1],
                image: response.rawMetadata.image,
                name: response.title,
                description: response.description,
            }
        //alert("precio: " + price);


    updateData(item);
    updateDataFetched(true);
    //console.log("address", addr)
    updateCurrAddress(addr);

}

async function buyNFT(tokenId) {
    try {
        const ethers = require("ethers");
        //After adding your Hardhat network to your metamask, this code will get providers and signers
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        //Pull the deployed contract instance
        let contract = new ethers.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, signer);
        const salePrice = ethers.utils.parseUnits(data.price, 'ether')
        updateMessage("Buying the NFT... Please Wait (Upto 5 mins)")
        //run the executeSale function
        let transaction = await contract.executeSale(tokenId, {value:salePrice});
        await transaction.wait();

        alert('You successfully bought the NFT!');
        updateMessage("");
    }
    catch(e) {
        alert("Upload Error"+e)
    }
}

    const params = useParams();
    const tokenId = params.tokenId;
    if(!dataFetched)
        getNFTData(tokenId);
        //alert(data.image)
    //alert(currAddress.toLowerCase());
    return(
        <div style={{"min-height":"100vh"}}>
            <Navbar></Navbar>
            <div className="flex ml-20 mt-20">
                <img src={data.image} alt="" className="w-2/5" />
                <div className="text-xl ml-20 space-y-8 text-white shadow-2xl rounded-lg border-2 p-5">
                    <div>
                        Name: {data.name}
                    </div>
                    <div>
                        Description: {data.description}
                    </div>
                    <div>
                        Price: <span className="">{data.price + " ETH"}</span>
                    </div>
                    <div>
                        Owner: <span className="text-sm">{data.owner}</span>
                    </div>
                    <div>
                        Contract: <span className="text-sm">{data.contract}</span>
                    </div>
                    <div>
                        Created by: <span className="text-sm">{data.seller}</span>
                    </div>
                    <div>
                        Token Id: <span className="text-sm">{tokenId}</span>
                    </div>
                    <div>
                    { (currAddress.toLowerCase() == data.owner) ?
                        <div className="text-emerald-700">You are the owner of this NFT</div> :
                        <button className="enableEthereumButton bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm" onClick={() => buyNFT(tokenId)}>Comprar este NFT</button>
                    
                    }
                    
                    <div className="text-green text-center mt-3">{message}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}