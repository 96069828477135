import Navbar from "./Navbar";
import { useParams } from 'react-router-dom';
import MarketplaceJSON from "../Marketplace.json";
import { useState } from "react";
import NFTTile from "./NFTTile";
import { Network, Alchemy } from "alchemy-sdk";
export default function Profile () {
    //const [data, updateData] = useState(true);
    const [data, updateData] = useState([]);
    const [dataFetched, updateFetched] = useState(false);
    const [address, updateAddress] = useState("0x");
    const [totalPrice, updateTotalPrice] = useState("0");
    const settings = {
        apiKey: "JAFC1ANRtXdH-_qdMGKp0R53m7A8_9ds", // Alchemy API Key.
        network: Network.ETH_GOERLI, // Network.
    };
   
    async function getNFTDataAlchemy(tokenId) {
        const ethers = require("ethers");
        let sumPrice = 0;
          
        //var listOfNFTs = new Array();
          
        const alchemyObject = new Alchemy(settings);
        const alchemyOwner = new Alchemy(settings);  
        // Print owner's wallet address:
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const addr = await signer.getAddress();
        updateAddress(addr);
        //alert(addr);
          
        // Print total NFT count returned in the response:
        const nftsForOwner = await alchemyObject.nft.getNftsForOwner(addr);
        //alert(nftsForOwner);
        //const totalNFTsFound = nftsForOwner.totalCount;
        //alert (totalNFTsFound);
        var items = new Array(); 
        
        for (const nft of nftsForOwner.ownedNfts) {
            let contract = nft.contract.address;
            //alert(contract);
            //alert(nft.tokenId);
            const owner = await alchemyOwner.nft.getOwnersForNft(contract, nft.tokenId);
            const index = owner.owners.length;
            //alert(owner.owners[index-1]);
            //alert("entre token pre Id: " + nft.tokenId);
            const response = await alchemyObject.nft.getNftMetadata(contract, nft.tokenId);
            const price = response.rawMetadata.price;
            let item = {
                price,
                tokenId: response.tokenId,
                seller: response.contract.contractDeployer,
                contract: response.contract.address,
                owner: owner.owners[index-1],
                image: response.rawMetadata.image,
                name: response.title,
                description: response.description,
            }
            sumPrice += Number(price);
            items.push(item);
            //alert ("suma: " + sumPrice);      
        
        }

        //alert (typeof (items));
        updateData(items);
        updateFetched(true);
        updateAddress(addr);
        updateTotalPrice(sumPrice.toPrecision(3));  

    }




    const params = useParams();
    const tokenId = params.tokenId;
    
    if(!dataFetched)
        //getNFTData(tokenId);
        //alert("inicie")
        //alert("TokenID: "+tokenId)
        //getNFTData(tokenId);
        getNFTDataAlchemy(tokenId);


    return (
        <div className="profileClass" style={{"min-height":"100vh"}}>
            <Navbar></Navbar>
            <div className="profileClass">
            <div className="flex text-center flex-col mt-11 md:text-2xl text-white">
                <div className="mb-5">
                    <h2 className="font-bold">Wallet Address</h2>  
                    {address}
                </div>
            </div>
            <div className="flex flex-row text-center justify-center mt-10 md:text-2xl text-white">
                    <div>
                        <h2 className="font-bold">NFTs Count</h2>
                        {data.length}
                    </div>
                    <div className="ml-20">
                        <h2 className="font-bold">Total Value</h2>
                        {totalPrice} ETH
                    </div>
            </div>
            <div className="flex flex-col text-center items-center mt-11 text-white">
                <h2 className="font-bold">My Collection</h2>
                <div className="flex justify-center flex-wrap max-w-screen-xl">
                    {data.map((value, index) => {
                    return <NFTTile data={value} key={index}></NFTTile>;
                    })}
                </div>
                <div className="mt-10 text-xl">
                    {data.length === 0 ? "Oops, No hay datos de NFTs para mostrar.":""}
                </div>
            </div>
            </div>
        </div>
    )
};